.App {
    text-align: center;
    margin-top: 50px;
}

input {
    padding: 10px;
    font-size: 16px;
    margin-bottom: 10px;
}

button {
    padding: 10px 20px;
    font-size: 16px;
    margin: 10px;
}

canvas {
    margin-top: 20px;
}
